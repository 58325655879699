import { Model } from "@vuex-orm/core";
import ForceCategory from "../../database/models/force_category";
export default class CountryForce extends Model {
  // This is the name used as mfca_countries_forcesodule name of the Vuex Store.
  static entity = "countriesForces";

  static fields() {
    return {
      id: this.attr(null),
      model: this.attr(null),
      code: this.attr(null),
      name: this.string(""),
      cover: this.attr(null),
      country: this.attr(null),
      category: this.attr(null),
      forceCategory: this.hasOne(ForceCategory, "id", "category"),
      show_quantity: this.attr(null),
      quantity: this.attr(null),
      order: this.attr(null),
      comments: this.attr(null),
      type: this.attr(null)
    };
  }
  static apiConfig = {
    dataKey: "records",
    actions: {
      getCountryForces(code, type, token) {
        return this.request({
          method: "get",
          url: `/countries_forces/?filter=code,eq,${code}&filter=type,eq,${type}`,
          cancelToken: token,
          dataTransformer: response => {
            return response.data.records;
          }
        });
      }
    }
  };
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
}
