
<style >
.v-overlay__content {
    width: 100%;
    height: 100%;
    display: flex;
}

.cardTitle {}
</style>
<template>
    <v-layout class="page my-auto pt-6">
        <v-row no-gutters class="mx-auto" style="width:100%">
            <v-col cols="12" md="4" sm="12" lg="4" v-for="forceType in forceTypes" :key="forceType.id" class="pa-3">
                <v-hover v-slot="{ hover }">
                    <v-card :to="{name:'AssetsIndex' ,params:{force_type:forceType.slug}}" dark>
                        <v-img :height="$vuetify.breakpoint.smAndDown ? 125 : 200" :src="forceType.cover" cover>
                            <v-overlay absolute :opacity="hover ? .25 : .50">
                                <v-card class="mt-auto" :color="hover ? '#000000' : '#00000050'"
                                    :width="hover ? '100%' : 'auto'">
                                    <v-card-title v-fontSize="$vuetify.breakpoint.smAndDown ? 18 : 28"
                                        class="text-uppercase font-weight-bold cardTitle" v-text="forceType.name">
                                    </v-card-title>
                                </v-card>
                            </v-overlay>
                        </v-img>
                    </v-card>
                </v-hover>

            </v-col>
            <v-col cols="12" md="6" sm="12" lg="6" class="pa-3">
                <v-hover v-slot="{ hover }">
                    <v-card to="national_ranking" dark>
                        <v-img :height="$vuetify.breakpoint.xl ? 200 : 140" src="images/national_ranking_cover.png"
                            cover>
                            <v-overlay absolute :opacity="hover ? .25 : .50">
                                <v-card class="mt-auto" :color="hover ? '#000000' : '#00000050'"
                                    :width="hover ? '100%' : 'auto'">
                                    <v-card-title v-fontSize="$vuetify.breakpoint.smAndDown ? 18 : 22"
                                        class="text-uppercase font-weight-bold "
                                        v-text="'NATIONAL RANKINGS BY MILITARY STRENGTH'" />
                                </v-card>
                            </v-overlay>
                        </v-img>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" md="6" sm="12" lg="6" class="pa-3">
                <v-hover v-slot="{ hover }">
                    <v-card to="armouries_index" dark>
                        <v-img :height="$vuetify.breakpoint.xl ? 200 : 140" src="images/fca_assets.png" cover>
                            <v-overlay absolute :opacity="hover ? .25 : .50">
                                <v-card class="mt-auto" :color="hover ? '#000000' : '#00000050'"
                                    :width="hover ? '100%' : 'auto'">
                                    <v-card-title v-fontSize="$vuetify.breakpoint.smAndDown ? 18 : 22"
                                        class="text-uppercase font-weight-bold"
                                        v-text="'Armouries Index: Rankings by Asset Type'" />
                                </v-card>
                            </v-overlay>
                        </v-img>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import ForceType from '../../js/database/models/force_type';
export default {
    data() {
        return {
            categories: {},
        }
    },
    methods: {


    },

    computed: {
        forceTypes() {
            return ForceType.query().orderBy("order").all();
        },
    }
}
</script>

<style>

</style>