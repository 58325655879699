
<style>
.backBlur {
	backdrop-filter: blur(4px);
}

.contain {
	object-fit: contain;
}

.bbl>div:not(:last-child) {
	border-bottom: 1px solid #e0e0e050;
}

.bbl .v-list>div:not(:last-child) {
	border-bottom: 1px solid #e0e0e050;
}

.bbl.v-list>a:not(:last-child) {
	border-bottom: 1px solid #e0e0e050;
}

.almostBlack {
	background: #050506;
}

.w-100 {
	width: 100%;
}

.flipX {
	transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
}

.flipXimg img,
.flipXimg .v-image {
	transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
}

.flipXimg .v-sheet.v-card.v-sheet--shaped {
	border-radius: 4px 24px;
}

.mainPreloader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	z-index: 99999;
	pointer-events: all;
	/* pointer-events: none; */
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

* {
	word-break: break-word !important;
}

.v-banner__wrapper {
	padding: 0 !important;
}
</style>
<template>
	<v-app>
		<app-nav></app-nav>
		<app-drawer></app-drawer>

		<v-main class="almostBlack" v-if="isReady == 1">
			<v-col cols="12" md="11" lg="11" class="pa-0 mx-auto fill-height">
				<v-card class="mx-auto pa-0 py-0 fill-height d-flex" flat tile :elevation="1">
					<router-view v-slot="{ Component, route }">
						<Transition name="fade" mode="out-in">
							<component :is="Component" :key="route.path"></component>
						</Transition>
					</router-view>
					<!-- <router-view v-slot="{ Component }">
						<transition name="fade" mode="in-out">
							<component :is="Component" />
						</transition>
					</router-view> -->
				</v-card>
			</v-col>
		</v-main>
		<app-footer />
		<v-sheet v-if="isReady == 0" class="mainPreloader" height="100vh" width="100vw" color="black">
			<h2 class="font-weight-bold red--text">MILITARY WATCH</h2>
		</v-sheet>
	</v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";/* 
Vue.component('app-nav', require('../js/components/AppNav.vue').default);
Vue.component('app-drawer', require('../js/components/AppDrawer.vue').default); */
import AppNav from '../js/components/AppNav.vue'
import AppDrawer from '../js/components/AppDrawer.vue'
import AppFooter from '../js/components/AppFooter.vue'
export default {
	name: "App",
	components: { AppNav, AppDrawer, AppFooter },
	data() {
		return {
			prevRoute: null
		}
	},
	metaInfo() {
		return this.metaInfo;
	},
	created() {
		console.log(
			`%c Created App`,
			"background: brown; color:white; padding:8px;"
		);

	},
	computed: {
		...mapState([
			"isReady",
			"metaInfo"
		]),
		isHome() {
			return this.$route.path == "/";
		},
	},
	methods: {
		...mapMutations(["saveObj"]),
	},
};
</script>
