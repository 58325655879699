
<style >
.v-overlay__content {
    width: 100%;
    height: 100%;
    display: flex;
}

.reportSelector.active .v-card:not(.v-slide-item--active) {
    opacity: 0.2;
}

@media screen and (max-width: 600px) {
    .v-menu__content.menuable__content__active.v-autocomplete__content {
        min-width: 100% !important;
    }
}
</style>
<template>
    <v-layout :class="`d-flex fill-height flex-column justify-start grey lighten-4`">
        <v-card dark tile flat :width="this.$vuetify.breakpoint.mdAndDown ? '100%' : '90%'"
            class="mx-auto d-flex flex-column">
            <v-img cover :height="'100%'" width="100%" style="position:fixed;left:0;top:0"
                gradient="45deg, black, #000000ad" src="/images/national_ranking_cover.png">
            </v-img>
            <div class="d-flex flex-column">
                <v-card-title style="background: #00000075;z-index: 1">
                    <h1 class="text-uppercase pa-2" v-lineHeight="'125%'" v-fontSize="$vuetify.breakpoint.xs ? 18 : 24"
                        v-text="'Armouries Index: Rankings by Asset Type'" />
                </v-card-title>
                <v-card-text class="my-auto" style="z-index: 1">
                    <v-hover v-slot="{ hover }">
                        <p class="typeDesc" v-lineHeight="$vuetify.breakpoint.sm ? '200%' : '150%'"
                            v-fontSize="$vuetify.breakpoint.xs ? '16' : '20'"
                            v-snip="{ lines: $vuetify.breakpoint.xs && !hover ? 10 : 0 }">
                            Countries ranked by the quantities of specific kinds of assets they field.
                        </p>
                    </v-hover>
                </v-card-text>
            </div>
        </v-card>
        <v-banner id="categorySelector" sticky app>
            <v-card :width="this.$vuetify.breakpoint.mdAndDown ? '100%' : '500px'" style="z-index:2"
                :color="force_type_report ? '#161616' : ''" tile dark rounded elevation="4"
                class="d-flex flex-column justify-center align-center pt-4 mx-auto">
                <v-expand-transition>
                    <p v-if="!force_type" class="text-uppercase font-weight-black font-italic"
                        v-text="'CHOOSE FIELD TO START'" style="z-index:2" />
                </v-expand-transition>
                <v-btn-toggle :value="this.$route.params.ft" :mandatory="force_type != null" rounded
                    @change="forceTypeChange">
                    <v-btn v-for="forceType in forceTypes" :key="forceType.slug" v-text="forceType.name"
                        :value="forceType.slug" v-fontSize="$vuetify.breakpoint.smAndDown ? 16 : 22"
                        v-letterSpacing="force_type == forceType.slug ? 4 : 2"
                        v-fontWeight="force_type == forceType.slug ? 800 : 600" />
                </v-btn-toggle>
                <v-divider class="my-2" />
                <v-expand-transition>
                    <v-select @change="forceTypeReportChange" v-if="force_type && force_type.forceTypeReports"
                        hide-details outlined filled label="CHOOSE CATEGORY" class="mx-2" item-value="slug"
                        item-text="name" :value="this.$route.params.ftr"
                        :style="`width:${$vuetify.breakpoint.smAndDown ? '90%' : '500px'}`"
                        :items="force_type.forceTypeReports" style="z-index:2;"
                        :full-width="$vuetify.breakpoint.smAndDown"
                        :menu-props="{ offsetY: true, contentClass: 'bbl', bottom: true }">
                        <template v-slot:item="{ item }">
                            <v-list-item-icon>
                                <v-img height="48px" width="48px" contain :src="item.icon" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-select>
                </v-expand-transition>
            </v-card>
        </v-banner>
        <v-card :elevation="4" :class="` mx-auto text-center`" :width="$vuetify.breakpoint.mdAndDown ? '100%' : '500px'"
            v-if="force_type_report">
            <v-list style="background: transparent;" class="bbl">
                <v-list-item class="px-1" dense color="transparent" tile v-for="(r, i) in countries" :key="i" flat
                    outlined>
                    <v-list-item-avatar size="48" class="my-auto">
                        <v-card color="grey lighten-2" outlined class="d-flex" width="100%" height="100%">
                            <h3 class="display-1 ma-auto font-weight-bold" v-text="r.order" />
                        </v-card>
                    </v-list-item-avatar>
                    <v-list-item-avatar tile size="64">
                        <v-img :src="getCountry(r.country).flag" width="48" height="48" contain />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex align-center ml-2">
                            <span v-text="getCountry(r.country).name" class="text-h5" />
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon tile class="my-auto px-2">
                        <v-chip dark class="font-weight-bold">
                            {{ r.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                        </v-chip>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-card>
    </v-layout>
</template>
<style>
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
<script>
import Country from '../../js/database/models/country';
import ForceType from '../../js/database/models/force_type';
import ForceTypeReport from '../../js/database/models/force_type_report';
import { VLazy } from "vuetify/lib";

export default {
    components: { VLazy },
    data() {
        return {}
    },
    methods: {
        getCountry(id) {
            return Country.find(id);
        },
        forceTypeChange(value) {
            console.log("forceTypeChange", value);
            this.$router.push({ name: 'ArmouriesIndex', params: { ft: value } })
        },
        forceTypeReportChange(value) {
            console.log("forceTypeChange", value);
            this.$router.push({ name: 'ArmouriesIndex', params: { ft: this.force_type.slug, ftr: value } })
        }
    },
    mounted() {
    },
    computed: {
        forceTypes() {
            return ForceType.query().orderBy("order").all();
        },
        force_type() {
            return this.$route.params.ft ? ForceType.query().where("slug", this.$route.params.ft)
                .with('forceTypeReports', query => {
                    query.with('countries', query => {
                        query.orderBy('quantity', 'desc');
                        query.where('quantity', query => query > 0);
                    })
                    query.where("is_ranking", true)
                    query.orderBy('order')
                }).first() : null;
        },
        force_type_report() {
            return this.$route.params.ftr ? ForceTypeReport.query().where("slug", this.$route.params.ftr)
                .with('countries', query => {
                    query.orderBy('quantity', 'desc');
                    query.where('quantity', query => query > 0);
                })
                .orderBy('order')
                .first() : null;
        },
        countries() {
            let countries = [];
            if (!this.force_type_report || !this.force_type_report.countries)
                return countries;

            this.force_type_report.countries.forEach((c, x) => {
                if (x == 0)
                    c['order'] = 1;
                else
                    c['order'] = this.force_type_report.countries[x - 1].quantity == c.quantity
                        ? this.force_type_report.countries[x - 1].order
                        : c.order = (x + 1);
            });
            return this.force_type_report.countries;
        },
    },
}
</script>

<style>

</style>