import { Database } from "@vuex-orm/core";
import ForceType from "./models/force_type";
import Country from "./models/country";
import MilitaryPowerTier from "./models/military_power_tier";
import ForceTypeReport from "./models/force_type_report";
import ForceCategory from "./models/force_category";
import ForceModel from "./models/force_model";
import CountryForce from "./models/country_force";
import CountryReport from "./models/country_report";
import CountryInfo from "./models/country_info";

const database = new Database();

database.register(ForceType);
database.register(ForceTypeReport);
database.register(ForceCategory);
database.register(ForceModel);
database.register(Country);
database.register(CountryForce);
database.register(CountryReport);
database.register(CountryInfo);

database.register(MilitaryPowerTier);

export default database;
