/* Meta:: setTitle('Force Comparison');
Meta:: setDescription('Military Watch Force Comparison APP , Compare Forces By Country');
Meta:: setKeywords('Force Comparison,forces,aerial forces , ground forces , military forces , military watch,compare forces,forces comnparison , all forces,all forces compare');
  */export default [
    {
        name: 'Home',
        path: '/',
        component: require('../../js/pages/Home.vue').default,
        meta: {
            title: 'Force Index - Military Watch Magazine',
            description: 'Welcome to Force Index by Military Watch Magazine, which provides a unique data display showing both the capabilities and assets of the world’s key military powers on land, at sea and in the air, and a ranking of their overall potency by algorithm. Included are precise details not only of the kinds of assets each country deploys, but also of the compositions of their inventories such as which classes of fighter, tank or warship are in service. At a time of rising international tensions and ongoing arms races in many key parts of the world, the Force Index provides insights valuable to understanding the global balance of power and how it is constantly evolving.',
            keywords: 'Force Index ,Force Comparison,forces,aerial forces , ground forces , military forces , military watch,compare forces,forces comnparison , all forces,all forces compare',
        }
    }, {
        name: 'Ranking',
        path: '/national_ranking',
        component: require('../../js/pages/NationalRanking.vue').default,
        meta: {
            title: 'National Ranking - Military Watch Magazine',
            description: `A formula-based ranking of the world’s foremost military powers taking into account over 50 different factors to determine each country’s position.Quantity and quality of armaments, while important, are supplemented by equally important evaluations of training, combat readiness, overseas military bases, defensive infrastructure and fortifications among many other assets considered.`,
            keywords: 'Force Index ,Force Comparison,forces,aerial forces , ground forces , military forces , military watch,compare forces,forces comnparison , all forces,all forces compare',
        }
    }, {
        name: 'ArmouriesIndex',
        path: '/armouries_index/:ft?/:ftr?',
        component: require('../../js/pages/ArmouriesIndex.vue').default,
        meta: {
            title: 'Armouries Index - Military Watch Magazine',
            description: 'Countries ranked by the quantities of specific kinds of assets they field.',
            keywords: 'Force Index ,Force Comparison,forces,aerial forces , ground forces , military forces , military watch,compare forces,forces comnparison , all forces,all forces compare',
        },
        props: true,
    }, {
        name: 'AssetsIndex',
        path: '/assets_index/:force_type/:ca?/:cb?',
        component: require('../../js/pages/AssetsIndex.vue').default,
        props: true
    },
    {
        path: '*', component: require('../../js/pages/Home.vue').default,
        meta: {
            title: 'Force Index - Military Watch Magazine',
            description: 'Military Watch Force Index',
            keywords: 'Force Index ,Force Comparison,forces,aerial forces , ground forces , military forces , military watch,compare forces,forces comnparison , all forces,all forces compare',
        }
    }
];
