<template>
  <v-hover v-slot="{ hover }">
    <v-card outlined flat width="100%" shaped class="overflow-hidden">
      <!--       <div v-html="img"></div>
 -->
      <v-img max-height="200px" eager min-height="200px" v-if="showImage" contain :src="countryForceModel.cover" />
      <v-list-item dense class="px-0">
        <v-list-item-content class="px-2 mt-auto pa-1">
          <v-list-item-title v-text="countryForceModel.name" class="text-body-2"
            v-snip="{ lines: hover ? 0 : 1, midWord: true }" />
          <v-list-item-subtitle v-if="countryForceModel" v-snip="{ lines: hover ? 0 : 1, midWord: true }"
            v-text="countryForceModel.comments" />
        </v-list-item-content>
        <v-list-item-icon style="height: auto;
    max-width: unset;
    padding: 8px;
    text-align: center;
    min-width: 40px;
    background: black;
    justify-content: center;"
          v-if="countryForceModel && countryForceModel.quantity != null && countryForceModel.show_quantity"
          :class="`ma-0 mt-auto ${flipX ? 'order-first' : ''}`">
          <h4 style="   
    color: white;
    letter-spacing: 1px;
    font-weight: 900;" v-text="quantity" class="px-2" />
        </v-list-item-icon>
      </v-list-item>

    </v-card>
  </v-hover>

</template>

<script>
export default {
  name: "CountryForceCard",
  props: {
    countryForceModel: { type: Object, default: null, required: true },
    showImage: { type: Boolean, default: true },
    flipX: { type: Boolean, default: false },
  },
  mounted() {
  },
  computed: {
    quantity() {
      if (!Number.isNaN(Number.parseInt(this.countryForceModel.quantity)))
        return Number.parseInt(
          this.countryForceModel.quantity
        ).toLocaleString();
      return this.countryForceModel.quantity;
    },
  },
};
</script>

<style>
</style>
