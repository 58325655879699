<style>
/* 

class="v-input pt-0 mt-0 countryAuto v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--is-focused theme--dark v-text-field v-text-field--single-line v-text-field--solo v-text-field--solo-inverted v-text-field--solo-flat v-text-field--is-booted v-text-field--enclosed v-select v-select--is-menu-active v-autocomplete primary--text" */
@media screen and (max-width: 600px) {
  .v-menu__content.menuable__content__active.v-autocomplete__content {
    left: 0px !important;
    min-width: 100% !important;
  }

  .countryAuto .v-input__control {
    margin: auto;
  }

  .rightMenu {
    right: 0;
    left: unset !important;
    top: 140px !important;
  }

  .leftMenu {
    top: 56px !important;
  }
}

.countryAuto.v-input--is-focused .reportHeadCard {
  display: none;
}

.countryAuto .v-list-item__content {
  padding: 0;
}

.countryAuto .v-input__slot {
  padding: 0 !important;
}

.compare .categoryItem:before {
  content: "";
  width: 1px;
  background: black;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  right: calc(50% - 3px);
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: pre-wrap;
}

.typeDesc {
  font-size: 16px;
  font-weight: 300;
}

.v-application--is-ltr .v-banner--is-mobile .v-banner__content {
  padding-right: 0%;
}

.countryAuto,
.countryAuto .v-input__control {
  height: 100%;
}

.countryAuto .v-input__prepend-outer {
  margin-bottom: 0;
  margin-top: 0 !important;
  margin-right: 0;
}

.countryAuto .v-input__append-inner {
  margin-top: auto;
  margin: auto !important;
}

.v-overlay__content {
  width: 100%;
  height: 100%;
  display: flex;
}

.bigCardBtn {
  font-size: 1rem;
  text-transform: uppercase;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.bx {
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}

.arrows {
  width: 60px;
  height: 72px;
}

.arrows path {
  stroke: #2994d1;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}

.theme--dark.v-input--is-focused {
  background-color: #1e1e1e !important;
}

.loaded.compare .countryA .v-select__slot .v-select__selections {
  order: 2;
}

.loaded.compare .countryA .v-select__slot .v-input__append-inner {
  order: 1;
}

.rightMenu {
  right: 0;
  left: unset !important;

}

.v-skeleton-loader__list-item-three-line {
  height: 64px;
}

.theme--dark.v-text-field--solo-inverted>.v-input__control>.v-input__slot {
  background: #1e1e1e;
}
</style>
<template >
  <v-layout :class="`d-flex fill-height flex-column justify-start grey lighten-4
  ${!this.countryB || 'compare'}   ${!this.loadedResults || 'loaded'} `">
    <v-img v-if="forceType" cover :height="'100vh'" min-width="100vw" :style="`position: fixed; top: 0; left: 0`"
      gradient="45deg, black, #1702028a" :src="forceType.background">
    </v-img>
    <v-card v-if="!loadedResults && forceType" dark tile flat
      :width="this.$vuetify.breakpoint.smAndDown ? '100%' : '90%'" class="mx-auto">
      <div class="d-flex flex-column backBlur">
        <v-card-title style="background: #00000075;z-index: 1">
          <h1 class="text-uppercase pa-2" v-lineHeight="'125%'" v-fontSize="$vuetify.breakpoint.xs ? 18 : 24"
            v-text="forceType.title" />
          <!--       <h1 class="text-uppercase pa-2" v-lineHeight="'125%'" v-fontSize="$vuetify.breakpoint.xs ? 18 : 24"
            v-text="forceType.description" /> -->
        </v-card-title>
        <v-card-text class="my-auto" style="z-index: 1" v-if="!loadedResults && ready">
          <v-hover v-slot="{ hover }">
            <h1 class="typeDesc" v-lineHeight="$vuetify.breakpoint.sm ? '200%' : '150%'"
              v-fontSize="$vuetify.breakpoint.xs ? '16' : '20'" v-html="forceType.description"
              v-snip="{ lines: $vuetify.breakpoint.xs && !hover ? 4 : !loadedResults && !hover ? 4 : 0 }">
            </h1>
          </v-hover>
        </v-card-text>
      </div>
    </v-card>

    <country-selector @done="selectionDone" v-if="forceType && $vuetify.breakpoint.smAndUp" :forceType="forceType"
      :countryA="countryA" :countryB="countryB" :compareA="compareA" @change="selectionChange"
      @compare="(compareA = true), (loadedResults = false), (loading = false)" :loading="loading"
      :loaded="loadedResults" />
    <v-banner dark v-if="countryA || $vuetify.breakpoint.xs" class="countrySelectorBanner mx-auto"
      :width="this.$vuetify.breakpoint.mdAndUp ? '90%' : this.$vuetify.breakpoint.smAndDown || loadedResults ? '100%' : '90%'"
      sticky app style="z-index: 4">
      <v-card tile width="100%" :disabled="loading" elevation="4" class="d-flex flex-row mx-auto mb-1">
        <v-row no-gutters align="center">
          <v-col id="leftCols" :cols="leftCols" v-if="leftVis" :order="leftOrder" :class="
            (!$vuetify.breakpoint.smAndUp || 'fill-height') +
            ' pa-2 pa-sm-0 pa-md-0 pa-lg-0 pa-xl-0 countryA'
          ">
            <v-autocomplete v-if="countries.length > 0" @keydown.backspace="fixBackspace"
              :disabled="UI.autoCompleteA.disabled" :height="'100%'" flat attach=".countrySelectorBanner" hide-no-data
              hide-details solo-inverted single-line v-model="countryA" :append-icon="
                ($vuetify.breakpoint.xs) || $vuetify.breakpoint.smAndUp && loadedResults ? '$dropdown' : ''
              " :menu-props="{
                offsetY: true,
                closeOnContentClick: true,
                contentClass: 'leftMenu',
                bottom: true
              }" :items="countries" class="pt-0 mt-0 countryAuto" ref="countryA" item-text="name" item-value="id">
              <template v-slot:prepend-inner>
                <v-card color="transparent" v-if="!countryA" flat width="100%" height="64px"
                  class="d-flex align-center mx-3">
                  <v-img contain src="/images/globe.png" height="32px" width="32px" />
                </v-card>
              </template>
              <template v-slot:label>
                <h5 class="font-italic font-weight-black" v-text="'Select country to start'" />
              </template>
              <template v-slot:selection="data">
                <v-card class="reportHeadCard" flat tile width="100%">
                  <v-list-item :class="$vuetify.breakpoint.xs ? 'px-2' : 'pa-0'">
                    <v-row no-gutters align="center" :class="`${!(loadedResults && countryB) || 'text-right'}`">
                      <v-col cols="2" :sm="loadedResults || compareA ? 4 : 5" md="4" lg="4" xl="4"
                        v-if="!($vuetify.breakpoint.xs && loadedResults && countryB)">
                        <img class="contain" :src="data.item.flag" style="postition: relative" width="100%"
                          :height="$vuetify.breakpoint.xs ? '48px' : '64px'"
                          :max-height="$vuetify.breakpoint.xs ? '48px' : '64px'" />
                      </v-col>
                      <v-col :cols="!($vuetify.breakpoint.xs && loadedResults && countryB) ? 10 : 12"
                        :sm="loadedResults || compareA ? 8 : 7" md="8" lg="8" xl="8">
                        <v-list-item-content>
                          <v-list-item-title>
                            <div v-fontSize="countrySelectionFontSize" class="cName mx-2" v-text="data.item.name">
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-chip dark x-small class="font-weight-bold" pill>
                              RANKING {{data.item.info.order }}
                            </v-chip>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-card>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col :cols="dividerCols" v-if="dividerVis" :order="dividerOrder">
            <v-divider />
          </v-col>
          <v-col :cols="centerCols" v-if="centerVis" :order="centerOrder">
            <v-row no-gutters align="center" justify="center" :class="this.loadedResults ? 'bx1' : ''">
              <v-col :cols="headerCols" v-if="headerVis" :order="headerOrder" class="d-flex flex-column">
                <div class="text-center text-uppercase font-weight-medium" v-html="headerText" />
              </v-col>
              <v-col :cols="headerCols" v-if="orDividerVis" :order="orDividerOrder" :class="`my-0 d-flex align-center`">
                <v-divider />
                <h5 :class="`font-weight-bold`" v-if="!(compareA && loadedResults)">
                  OR
                </h5>
                <v-divider />
              </v-col>
              <v-col :cols="leftButtonCols" v-if="leftButtonVis" :order="leftButtonOrder"
                class="pa-2 pa-sm-1 text-center">
                <v-btn :loading="loading" depressed
                  :height="this.compareA || this.$vuetify.breakpoint.smAndDown ? '' : 64" :text="loading"
                  :width="!compareA ? '100%' : 'auto'" dark :large="$vuetify.breakpoint.xs"
                  :small="$vuetify.breakpoint.smAndUp && compareA && !countryB" color="blue darken-4"
                  @click="selectionDone">
                  <div :class="`d-flex w-100 ${this.compareA || this.$vuetify.breakpoint.smAndDown
                  ? ''
                  : 'flex-column'
                  }`">
                    <v-icon large>mdi-view-split-vertical</v-icon>
                    <div class="my-auto" v-html="leftButtonText"></div>
                  </div>
                </v-btn>
              </v-col>
              <v-col :cols="rightButtonCols" v-if="rightButtonVis" :order="rightButtonOrder"
                class="pa-2 pa-sm-1 text-center">
                <v-btn :loading="loading" depressed
                  :height="this.compareA || this.$vuetify.breakpoint.smAndDown ? '' : 64" :text="loading"
                  :width="!compareA ? '100%' : 'auto'" dark :large="$vuetify.breakpoint.xs" color="primary" @click="
                    compareA || $vuetify.breakpoint.xs
                      ? selectionDone()
                      : (compareA = true)
                  ">
                  <div :class="`d-flex w-100 ${this.compareA || this.$vuetify.breakpoint.smAndDown
                  ? ''
                  : 'flex-column'
                  }`">
                    <v-icon large>mdi-compare</v-icon>
                    <div class="my-auto" v-html="rightButtonText"></div>
                  </div>
                </v-btn>
              </v-col>
              <v-col :cols="buttonsCols" v-if="buttonsVis" :class="`d-flex py-1  align-center justify-space-around`">
                <v-btn small @click="back()" class="flex-grow-1 mx-2">
                  <v-icon class="mr-2">mdi-arrow-left</v-icon>
                  Back
                </v-btn>
                <v-divider vertical class="mx-2" />
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" small class="flex-grow-1 mx-2" text v-bind="attrs" v-on="on">
                      <v-icon class="mr-2">mdi-share-variant</v-icon>
                      SHARE
                    </v-btn>
                  </template>
                  <v-card class="d-flex justify-space-around py-2">
                    <ShareNetwork network="facebook" :url="currentURL" :title="metaInfo.title"
                      :description="metaInfo.title">
                      <v-icon color="#4267B2" x-large>mdi-facebook</v-icon>
                    </ShareNetwork>
                    <ShareNetwork network="twitter" :url="currentURL" :title="metaInfo.title"
                      :description="metaInfo.title">
                      <v-icon color="#1DA1F2" x-large>mdi-twitter</v-icon>
                    </ShareNetwork>
                    <ShareNetwork network="whatsapp" :url="currentURL" :title="metaInfo.title"
                      :description="metaInfo.title">
                      <v-icon color="#25D366" x-large>mdi-whatsapp</v-icon>
                    </ShareNetwork>
                  </v-card>
                </v-menu>


              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="rightCols" v-if="rightVis" :order="rightOrder" :class="
            (!$vuetify.breakpoint.smAndUp || 'fill-height') +
            ' pa-2 pa-sm-0 pa-md-0 pa-lg-0 pa-xl-0 d-flex'
          ">
            <v-card flat tile class="d-flex pa-2 my-auto justify-end align-center ml-auto"
              v-if="!countryB && !this.$vuetify.breakpoint.xs && !this.loadedResults">
              <v-skeleton-loader type="list-item-three-line" class="flipX" style="height: 64px; width: 250px">
              </v-skeleton-loader>
              <v-skeleton-loader type="image" style="height: 64px; width: 64px">
              </v-skeleton-loader>
            </v-card>
            <v-autocomplete v-else @keydown.backspace="fixBackspace"
              :disabled="!loadedResults && !this.$vuetify.breakpoint.xs" :height="'100%'" flat :append-icon="
                ($vuetify.breakpoint.xs) || $vuetify.breakpoint.smAndUp && loadedResults ? '$dropdown' : ''
              " attach=".countrySelectorBanner" hide-no-data hide-details solo-inverted single-line v-model="countryB"
              :menu-props="{
                offsetY: true,
                contentClass: 'rightMenu',
                closeOnContentClick: true,
              }" :items="countries" ref="countryB" class="pt-0 mt-0 countryAuto" item-text="name" item-value="id">
              <template v-slot:prepend-inner>
                <v-card color="transparent" v-if="!countryB" flat width="100%" height="64px"
                  class="d-flex align-center mx-3">
                  <v-img contain src="/images/globe.png" height="32px" width="32px" />
                </v-card>
              </template>
              <template v-slot:label>
                <v-card flat color="transparent" class="pa-0 text-center" width="100%" height="64px">
                  <h5 class="font-italic font-weight-black" v-text="
                    $vuetify.breakpoint.xs
                      ? 'Select country to compare'
                      : 'Compare Country'
                  " />
                </v-card>
              </template>
              <template v-slot:selection="data">
                <v-card class="reportHeadCard" :disabled="data.item.id == countryA" flat tile width="100%">
                  <v-list-item :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'pa-0'">
                    <v-row no-gutters align="center">
                      <v-col cols="2" :sm="loadedResults || compareA ? 4 : 5" md="4" lg="4" xl="4"
                        v-if="!($vuetify.breakpoint.xs && loadedResults && countryB)"
                        :order="!($vuetify.breakpoint.xs && loadedResults && countryB) ? 2 : 1">
                        <img :src="data.item.flag" style="postition: relative" :contain="$vuetify.breakpoint.xs"
                          class="contain" width="100%" :height="$vuetify.breakpoint.xs ? '48px' : '64px'"
                          :max-height="$vuetify.breakpoint.xs ? '48px' : '64px'" />
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.xs && loadedResults ? 12 : 10"
                        :sm="loadedResults || compareA ? 8 : 7" md="8" lg="8" xl="8"
                        :order="loadedResults || ($vuetify.breakpoint.smAndUp && compareA) ? 1 : 2">
                        <v-list-item-content>
                          <v-list-item-title>
                            <div v-fontSize="countrySelectionFontSize" :class="`cName mx-2`" v-text="data.item.name">
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-chip dark x-small class="font-weight-bold" pill>
                              RANKING {{data.item.info.order }}
                            </v-chip>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-card>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card>
    </v-banner>
    <v-card :loading="loading" :disabled="loading" outlined flat tile
      class="d-flex flex-column mx-auto bbl countryReports" :width="reportsUI.width" v-if="loadedResults">
      <v-hover v-slot="{ hover }" v-for="report in forceType.forceTypeReports" :key="report.id">
        <v-card flat tile :color="hover ? 'grey lighten-4' : ''">
          <v-row no-gutters class="mx-0 px-2">
            <v-col :cols="reportNameCols" :class="`d-flex align-center ${countryB ? 'flex-column text-center' : ''}`"
              :order="countryB ? 2 : 1">
              <v-img :src="report.icon" contain width="100px" max-width="100px" height="50px" />
              <h5 class="ml-2">{{ report.name }}</h5>
            </v-col>
            <v-col :cols="reportQuantityCols" :order="countryB ? 1 : 2"
              :class="`text-${countryB ? 'center' : 'right'} my-auto`">
              <v-chip pill dark>
                <h3 v-fontSize="$vuetify.breakpoint.xs ? 16 : 24" class="font-weight-bold">
                  {{getCountryReport(report.id, countryA).quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                </h3>
              </v-chip>
            </v-col>
            <v-col :cols="reportQuantityCols" v-if="countryB" order="3" :class="`text-center my-auto`">
              <v-chip pill dark>
                <h3 v-fontSize="$vuetify.breakpoint.xs ? 16 : 24" class="font-weight-bold">
                  {{getCountryReport(report.id, countryB).quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                </h3>
              </v-chip>
            </v-col>
            <v-col cols="12" order="4">
              <v-expand-transition>
                <div v-if="hover" :class="`caption ${!countryB || 'text-center px-md-16 px-2 px-lg-16 px-sm-8'}` "
                  v-html="report.description"></div>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-card>
    <v-divider class="my-4" v-if="loadedResults" />
    <v-card id="forces" color="transparent" outlined flat tile width="100%" class="mx-auto"
      v-if="loadedResults && !loading">
      <v-card color="transparent" v-for="category in categories" :key="category.id" flat tile style="cursor: pointer"
        width="100%" class="categoryItem">
        <recursive-item :class="`d-flex flex-column`" prop="categories" :item="category">
          <template v-slot:title="{ item, inner }">
            <v-card dark :tile="!inner"
              :class="`pa-2 text-uppercase text-center font-weight-${inner ? 'bold inner mx-auto' : 'black mr-auto'}`"
              v-fontSize="
                $vuetify.breakpoint.smAndDown ? (inner ? 14 : 16) : inner ? 18 : 24
              " style="z-index: 3" :color="`${!inner ? 'black' : 'primary darken-4'}`" v-text="item.name"
              :width="inner ? $vuetify.breakpoint.smAndDown ? '100%' : '90%' : ''">
            </v-card>
          </template>
          <template v-slot:body="{ item }">
            <v-card flat color="white" :width="$vuetify.breakpoint.mdAndUp ? '90%' : '100%'" class="mx-auto py-4"
              v-if="categoryFilter.includes(item.id)">
              <v-row no-gutters class="categoryForces no-wrap pl-2">
                <v-col :cols="forcesRight.cols">
                  <div v-if="
                    getCountry(countryA).models.filter((m) => m.category == item.id)
                      .length > 0
                  " tile height="100%">
                    <v-row no-gutters :style="`flex-direction:row-reverse`">
                      <v-col :cols="forcesRight.modelCols" class="pa-2" :key="model.id" v-for="model in getCountry(countryA).models.filter(
                        (m) => m.category == item.id
                      )">
                        <component :is="$browserDetect.isIOS ? 'div' : 'v-lazy'" :class="`modelCard`" :min-height="220"
                          :transition="`scroll-x-transition`">
                          <country-force-card :countryForceModel="model" :flipX="!countryB" :min-height="220" />
                        </component>
                      </v-col>
                    </v-row>
                  </div>
                  <v-card color="transparent" v-else
                    class="d-flex align-center justify-center flex-column ma-auto pa-2 text-center" flat width="100%"
                    height="100%">
                    <strong v-text="'No Assets'" />
                  </v-card>
                </v-col>
                <v-col :cols="6" v-if="countryB" :class="`countryForces`" style="min-height: 100%">
                  <div v-if="
                    getCountry(countryB).models.filter((m) => m.category == item.id)
                      .length > 0
                  " tile height="100%">
                    <v-row no-gutters>
                      <v-col :cols="forcesRight.modelCols" :key="model.id" :class="`pa-2 `" v-for="model in getCountry(countryB).models.filter(
                        (m) => m.category == item.id
                      )">
                        <component :is="$browserDetect.isIOS ? 'div' : 'v-lazy'" class="modelCard flipXimg"
                          :min-height="220" :transition="`scroll-reverse-x-transition`">
                          <country-force-card :countryForceModel="model" :flipX="true" :min-height="220" />
                        </component>
                      </v-col>
                    </v-row>
                  </div>
                  <v-card color="transparent" v-else
                    class="d-flex align-center justify-center flex-column ma-auto pa-2 text-center" flat width="100%"
                    height="100%">
                    <strong v-text="'No Assets'" />
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </recursive-item>
      </v-card>
    </v-card>
  </v-layout>
</template>

<script>

import CountrySelector from "../components/CountrySelector.vue";
import RecursiveItem from "../components/RecursiveItem.vue";
import CountryForceCard from "../components/CountryForceCard.vue";
import Country from "../../js/database/models/country";
import ForceType from "../../js/database/models/force_type";
import CountryReport from "../../js/database/models/country_report";
import MilitaryPowerTier from "../../js/database/models/military_power_tier";
import ForceCategory from "../database/models/force_category";
import { mapState } from "vuex";
import { VLazy } from "vuetify/lib";
export default {
  props: {
    force_type: { type: String, default: null },
    ca: { type: String, default: null },
    cb: { type: String, default: null },
  },
  components: { CountrySelector, RecursiveItem, CountryForceCard, VLazy },
  data() {
    return {
      weDid: false,
      forceType: null,
      countryA: null,
      countryB: null,
      compareA: false,
      tabs: null,
      forceTypeReport: "",
      showingCountryA: false,
      showingCountryB: false,
      loading: false,
      loadedResults: false,
      ready: false,
      data: [],
      categoryFilter: [],
      categories: [],
      countries: [],
    };
  },
  created() { },
  mounted() {
    console.log(this.force_type);
    this.loading = true;
    this.initData();
    /*   setTimeout(async () => {
        if (this.countryA || this.countryB) await this.loadData();
        this.loading = false;
        this.ready = true;
      }, 1); */
  },
  methods: {
    async initData() {
      this.ready = false;
      this.loadedResults = false;
      this.categories = [];
      this.categoryFilter = [];
      this.data = [];
      this.countryA = null;
      this.countryB = null;
      this.forceType = ForceType.query()
        .where("slug", this.force_type)
        .with("forceTypeReports", (query) => {
          query.where("is_report", true);
          query.orderBy("order");
        })
        .with("categories", (query) => {
          query.where("parent", null);
          query.orderBy("order");
          query.with("categories", this.recursiveCategories);
        })
        .first();
      console.log("This OUR FORCE TYPE", this.forceType)
      this.countries = Country.query()
        .orderBy("name")
        .with('info')
        .all()
        .map((c) => {
          c["disabled"] = false;

          return c;
        });

      if (this.ca) this.countryA = this.getCountry(this.ca).id; else this.countryA = null;
      if (this.cb) (this.compareA = true), (this.countryB = this.getCountry(this.cb).id); else this.countryB = null, this.compareA = false;
      if (this.ca || this.cb) await this.loadData();
      this.loading = false;
      this.$nextTick(() => {
        this.ready = true;
      })

    },
    async loadData() {
      this.loadedResults = false;
      this.loading = true;

      if (!(window.data && window.data["countries"])) {
        try {
          console.log("Load From Online");
          await Country.api().fetchById([this.currentCountries], this.forceType.id);
        } catch (error) {
          console.log("Cant Load", error);
        }
      } else {
        window.data = null;
      }
      this.data = Country.query()
        .whereIdIn(this.currentCountries)
        .with("reports", query => {
          query.where('type', this.forceType.id);
          query.where('is_report', 1);
          query.orderBy('order')
        })
        .with("models", query => {
          query.where('type', this.forceType.id);
        })
        .get();
      this.categoryFilter = [
        ...new Set(this.data.flatMap((d) => d.models.flatMap((t) => t.category))),
      ];
      this.categories = ForceCategory.adoptCaegories(this.categoryFilter);
      this.loadedResults = true;
      if (this.ready) {
        const params = {};
        if (this.countryA != null) params.ca = this.getCountry(this.countryA).slug;
        if (this.countryB != null) params.cb = this.getCountry(this.countryB).slug;
        if (JSON.stringify(this.$route.params) != JSON.stringify(params)) {
          this.$router.push({ name: "AssetsIndex", params: params });
        }
      }

      this.loading = false;
    },
    fixBackspace(ev) {
      if (ev.target.value.length == 1) {
        ev.preventDefault();
        ev.target.value = "";
      }
    },
    getCountryReport(reportId, countryId) {
      let report = CountryReport.query()
        .where("report", reportId)
        .where("country", countryId)
        .first();

      return report ? report : { quantity: 0 };
      // return this.data.find(x => x.report_id == reportId && x.country_id == countryId);
    },

    getCountry(id) {
      return Country.query()
        .where(isNaN(id) ? "slug" : "id", id)
        .with("models", query => {
          query.where('type', this.forceType.id);
          query.orderBy('order')
        })
        .first();
    },
    getCountryRanking(country) {
      console.log(country.tier);
      let powerTier = MilitaryPowerTier.find(country.tier);
      if (!powerTier || !country) return 0;
      let ranking = country.order;
      let beforeTiers = MilitaryPowerTier.query()
        .where("order", (o) => o < powerTier.order)
        .all();
      if (beforeTiers.length > 0)
        ranking += Country.query()
          .where("tier", (t) => beforeTiers.flatMap((bt) => bt.id).includes(t))
          .all().length;

      return ranking + 1;
    },
    selectionChange(countryId) {
      this.loadedResults = false;
      this.loading = false;
      if ([this.countryA, this.countryB].includes(countryId)) {
        if (this.countryA == countryId) {
          this.countryA = this.countryB;
        }
        this.countryB = null;
        if (!this.countryA) this.compareA = false;
        return;
      }
      if (this.compareA) {
        this.countryB == countryId ? (this.countryB = null) : (this.countryB = countryId);
      } else {
        this.countryA == countryId ? (this.countryA = null) : (this.countryA = countryId);
      }

    },

    selectionDone() {
      if (this.compareA && !this.countryB) this.compareA = false;
      this.loadData();
    },
    back() {
      this.countryB = null;
      this.compareA = null;
      this.countryA = null;
      this.loading = false;
      this.loadedResults = false;
      this.data = [];
      this.$router.push({ name: 'AssetsIndex', params: { force_type: this.force_type } })
    },
    recursiveCategories(query) {
      query.orderBy("order");
      query.with("categories", this.recursiveCategories);
    },
  },
  computed: {
    ...mapState(['metaInfo']),
    currentURL() {
      return window.location.host + this.$route.path;
    },
    UI() {
      return {
        autoCompleteA: {
          disabled: !this.loadedResults && !this.$vuetify.breakpoint.xs
        }
      }
    },
    currentCountries() {
      let countries = [this.countryA];
      if (this.countryB) countries.push(this.countryB);
      return countries;
    },
    militaryPowerTiers() {
      return MilitaryPowerTier.query().with("countries").orderBy("order").all();
    },
    forceTypes() {
      return ForceType.query().orderBy("order").all();
    },
    leftVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return true;
      }
    },
    leftCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.loadedResults && this.countryB ? 6 : 12;
        case "sm":
          return this.compareA || this.loadedResults ? 4 : 6;
        case "md":
          return this.compareA || this.loadedResults ? 4 : 6;
        default:
          return this.compareA || this.loadedResults ? 4 : 6;
      }
    },
    leftOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        default:
          return 1;
      }
    },
    centerCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return this.loadedResults || this.compareA ? 4 : 6;
        case "md":
          return this.loadedResults || this.compareA ? 4 : 6;
        default:
          return this.loadedResults || this.compareA ? 4 : 6;
      }
    },
    centerVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryA;
        default:
          return true;
      }
    },
    centerOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryA ? 4 : 3;
        case "sm":
          return this.loadedResults || this.compareA ? 2 : 4;
        case "md":
          return this.loadedResults || this.compareA ? 2 : 4;
        default:
          return this.loadedResults || this.compareA ? 2 : 4;
      }
    },
    rightVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryA && !(this.loading && !this.countryB);
        case "sm":
          return (this.countryA && this.loadedResults) || this.compareA;
        case "md":
          return (this.countryA && this.loadedResults) || this.compareA;
        default:
          return (this.countryA && this.loadedResults) || this.compareA;
      }
    },
    rightCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.loadedResults && this.countryB ? 6 : 12;
        default:
          return 4;
      }
    },
    rightOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryB || this.loadedResults ? 2 : 4;
        case "sm":
          return 3;
        case "md":
          return 3;
        default:
          return 3;
      }
    },
    countrySelectionFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryB && this.loadedResults ? 12 : 18;
        case "sm":
          return this.loadedResults || this.compareA ? 14 : 20;
        case "md":
          return this.loadedResults || this.compareA ? 14 : 20;
        default:
          return this.loadedResults || this.compareA ? 14 : 20;
      }
    },
    dividerVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryA;
        default:
          return true;
      }
    },
    dividerCols() {
      return 12;
    },
    dividerOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryB && this.loadedResults ? 3 : 2;
        default:
          return 12;
      }
    },
    orDividerVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return !this.countryB && !this.loading && !this.loadedResults;
        case "sm":
          return (
            !this.loadedResults && !this.countryB && !(this.loading && !this.countryB)
          );
        case "md":
          return this.compareA && !this.loadedResults && !this.loading && !this.countryB;
        default:
          return this.compareA && !this.loadedResults && !this.loading && !this.countryB;
      }
    },
    orDividerCols() {
      return 12;
    },
    orDividerOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 4;
        case "md":
          return 2;
        default:
          return 2;
      }
    },
    headerVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.compareA && !this.countryB;
        case "sm":
          return !this.loadedResults && (this.compareA && !this.countryB);
        case "md":
          return !this.loadedResults && (this.compareA && !this.countryB);
        default:
          return !this.loadedResults && (this.compareA && !this.countryB);
      }
    },
    headerCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.compareA ? 12 : 6;
        case "sm":
          return 12;
        case "md":
          return 12;
        default:
          return 12;
      }
    },
    headerOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 1;
        case "md":
          return 1;
        default:
          return 1;
      }
    },
    headerText() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.compareA && this.loadedResults
            ? `<strong>${this.forceType.name}</strong> | Force Comparison`
            : !this.compareA
              ? this.forceType.name + " Assets & Capabilities"
              : "Select Another Country";
        case "sm":
          return `<h5 class='font-weight-light mt-2'>${this.loadedResults ? "Assets & Capabilities" : "Select Another Country"
            } </h5>`;
        case "md":
          return `<h5 class='font-weight-light mt-2'>${this.loadedResults ? "Assets & Capabilities" : "Select Another Country"
            } </h5>`;
        default:
          return `<h5 class='font-weight-light mt-2'>${this.loadedResults ? "Assets & Capabilities" : "Select Another Country"
            } </h5>`;
      }
    },
    descText() {
      return this.forceType.name + " Warfare Assets";
    },
    leftButtonVis() {
      return !(this.loadedResults || (this.loading && this.compareA) || this.countryB);
    },
    leftButtonCols() {
      return this.compareA || this.$vuetify.breakpoint.smAndDown ? 12 : 6;
    },
    leftButtonOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return this.compareA ? 3 : 1;
        case "lg":
          return this.compareA ? 3 : 1;
        case "xl":
          return this.compareA ? 3 : 1;
        default:
          return 3;
      }
    },

    leftButtonText() {
      return `Show Capabilities`;
    },
    rightButtonVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryB && !this.loadedResults;
        case "sm":
          return (
            this.countryA &&
            !(this.compareA && !this.countryB) &&
            !this.loadedResults &&
            !(this.loading && !this.countryB)
          );
        case "md":
          return (
            this.countryA &&
            !(this.compareA && !this.countryB) &&
            !this.loadedResults &&
            !(this.loading && !this.countryB)
          );
        default:
          return (
            this.countryA &&
            !(this.compareA && !this.countryB) &&
            !this.loadedResults &&
            !(this.loading && !this.countryB)
          );
      }
    },
    rightButtonCols() {
      return this.compareA || this.$vuetify.breakpoint.smAndDown ? 12 : 6;
    },
    rightButtonOrder() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 3;
        case "sm":
          return 1;
        case "md":
          return 1;
        default:
          return 1;
      }
    },
    rightButtonText() {
      return !this.countryB ? "Compare Countries" : "Show Comparison";
    },
    buttonsVis() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.loadedResults;
        case "sm":
          return this.loadedResults;
        case "md":
          return this.loadedResults;
        default:
          return this.loadedResults;
      }
    },
    buttonsCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        default:
          return 12;
      }
    },

    reportNameCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.countryB ? 6 : 9;
        case "sm":
          return this.countryB ? 6 : 8;
        case "md":
          return this.countryB ? 6 : 8;
        default:
          return this.countryB ? 6 : 8;
      }
    },
    reportQuantityCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 3;
        case "sm":
          return 3;
        case "md":
          return 3;
        default:
          return 3;
      }
    },
    reportsUI() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return {
            width: "100%",
          };
        case "sm":
          return {
            width: "90%",
          };
        case "md":
          return {
            width: "90%",
          };
        default:
          return {
            width: "90%",
          };
      }
    },
    forcesUI() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return {
            width: "100%",
          };
        case "sm":
          return {
            width: "100%",
          };
        case "md":
          return {
            width: "100%",
          };
        default:
          return {
            width: "100%",
          };
      }
    },
    forcesRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return {
            cols: this.countryB ? 6 : 12,
            modelCols: this.countryB ? 12 : 6,
          };
        case "sm":
          return {
            cols: this.countryB ? 6 : 12,
            modelCols: this.countryB ? 6 : 4,
          };
        case "md":
          return {
            cols: this.countryB ? 6 : 12,
            modelCols: this.countryB ? 6 : 4,
          };
        case "lg":
          return {
            cols: this.countryB ? 6 : 12,
            modelCols: this.countryB ? 4 : 3,
          };
        default:
          return {
            cols: this.countryB ? 6 : 12,
            modelCols: this.countryB ? 4 : 3,
          };
      }
    },
  },
  watch: {
    force_type() {
      this.ready = false;
      this.loading = true;
      this.weDid = true;
      this.initData();
    },
    countryA(v) {
      if (this.ready)
        this.weDid = true,
          this.$nextTick(() => {
            if (this.$refs.countryA)
              this.$refs.countryA.blur();
            if (v && this.loadedResults) this.selectionDone();
            else window.scrollTo(0, 0);
          });

      this.countries.forEach((c) => { c.disabled = this.currentCountries.includes(c.id) });
    },
    countryB(v) {
      if (this.ready)
        this.weDid = true,
          this.$nextTick(() => {
            if (this.$refs.countryB)
              this.$refs.countryB.blur();
            this.countries.forEach((c) => {
              if (c.id == this.countryB) c.disabled = true;
              else c.disabled = false;
            });
            if (v && this.loadedResults) (this.compareA = true), this.selectionDone();
            else window.scrollTo(0, 0);
          });
      this.countries.forEach((c) => { c.disabled = this.currentCountries.includes(c.id) });
    },
    $route(to, from) {
      if (this.weDid) this.weDid = false;
      else this.initData();
      this.countries.forEach((c) => { c.disabled = this.currentCountries.includes(c.id) });
    }
    /*
    ca(v) {
      console.log("CA Changed ", v),
        this.initData();
    },
    cb(v) {
      console.log("CB Changed ", v),
        this.initData();
    }, */


  },



};
</script>

