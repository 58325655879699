
<style>
.v-banner__wrapper {
    padding: 0 !important;
    border-bottom: unset !important;
}

.v-btn__content {
    max-width: 100%;
}

.reportHeadCard {}

.reportHeadCard .cName {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
}

.reportHeadCard .cStrengths {
    white-space: normal;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    border: unset !important;
}

.countrySelectorBanner {}

.countrySelectorBanner .leftCols {}

.countrySelectorBanner .centerCols {}

.countrySelectorBanner .rightCols {}
</style>
<template>
    <v-expand-transition>
        <v-card v-show="!loaded" :disabled="loading" :width="this.$vuetify.breakpoint.smAndDown ? '100%' : '90%'"
            class="mx-auto" flat outlined>
            <v-text-field hide-details filled v-model="searchCountry" label="Search Countries"
                append-icon="mdi-magnify" />
            <v-card width="100%" class="mx-auto countrySelector" flat outlined :max-height="360" :height="360">
                <v-row no-gutters>
                    <v-col cols="3" sm="4" md="3" lg="3"
                        v-for="country in countries.filter(c => c.name.toLowerCase().includes(searchCountry.toLowerCase()))"
                        :key="country.id">
                        <v-list-item class="my-auto d-flex" style="border: 1px solid #f0f0f0;"
                            @click="$emit('change', country.id)" flat outlined height="64px" tile
                            :dark="[countryA, countryB].includes(country.id)" :class="getSelectColor(country.id)"
                            min-height="64px">
                            <v-img eager :src="country.flag" height="32" max-width="48" contain class="mr-2">
                            </v-img>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span v-fontSize="14" v-text="country.name"></span>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-show="[countryA, countryB].includes(country.id)" class="ma-0">
                                <v-btn @click="$emit('clear', country.id)" tile text icon >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <!--  <v-card flat outlined height="64px" tile :dark="[countryA, countryB].includes(country.id)"
                            :color="getSelectColor(country.id)" class="d-flex" min-height="64px">

                        </v-card> -->
                    </v-col>
                </v-row>
            </v-card>
        </v-card>

    </v-expand-transition>

</template>
   
<script>
import Country from '../../js/database/models/country';
import MilitaryPowerTier from '../../js/database/models/military_power_tier';

export default {
    data() {
        return {
            searchCountry: "",
        }
    },
    props: {
        countryA: {
            type: Number,
            default: null
        },
        countryB: {
            type: Number,
            default: null
        },
        forceType: {
            type: Object,
            default: null
        },
        compareA: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        loaded: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$Scrollbar.init(document.querySelector(".countrySelector"), {
                alwaysShowTracks: true,
                syncCallbacks: true,
                continuousScrolling: true,
            });
        });
    },
    computed: {
        countries() {
            return Country.query().orderBy("name").all();
        },
        leftCols() {
            return 4
        },
        centerCols() {
            return this.loading ? 12 : !this.compareA ? 8 : 4
        },
        rightCols() {
            return 4
        },
        rightColsVis() {
            return (this.loaded && this.countryB) || ((this.countryB || this.compareA) && !this.loading && !this.loaded)
        },
        descText() {
            return this.forceType.name + " Warfare Assets"
        },
        leftButtonVis() {
            return !(this.loaded || (this.loading && this.compareA) || this.countryB);
        },
        leftButtonCols() {
            return this.compareA ? 12 : 6;
        },
        headerVis() {
            return (this.loaded || this.compareA) && !this.rightButtonVis;
        },
        headerCols() {
            return this.compareA ? 12 : 6;
        },
        headerText() {
            return this.compareA && this.loaded ? `<strong>${this.forceType.name}</strong> | Force Comparison` : !this.compareA ? this.forceType.name + ' Assets & Capabilities' : "Select Another Country";
        },
        rightButtonCols() {
            return this.compareA ? 12 : 6;
        },
        rightButtonVis() {
            return (this.compareA && this.countryB && !this.loaded) || !(this.loaded || this.compareA || (this.loading && !this.compareA));
        },
        rightButtonText() {
            return !this.countryB ? 'Compare Countries' : 'Show Comparison';
        },
        leftButtonText() {
            return `Show ${this.forceType.name} Capabilities`
        }
    },
    methods: {
        getCountryRanking(country) {
            let powerTier = MilitaryPowerTier.find(country.tier);
            if (!powerTier || !country) return 0;
            let order = country.order;
            let beforeTiers = MilitaryPowerTier.query()
                .where("order", (o) => o < powerTier.order)
                .all();
            if (beforeTiers.length > 0)
                order += Country.query()
                    .where("tier", (t) => beforeTiers.flatMap((bt) => bt.id).includes(t))
                    .all().length;

            return order + 1;
        },
        getSelectColor(countryId) {
            if (this.countryA == countryId) return "primary darken-4";
            if (this.countryB == countryId) return "blue darken-4";
            return "";
        },
        getCountry(countryId) {
            return Country.find(countryId);
        }
    },
    watch: {
        countryA(newVal, oldVal) {
            this.$nextTick(() => {
                this.$Scrollbar.init(document.querySelector(".countrySelector")).update();    //    console.log();
            })
        },
    }
}
</script>

<style>

</style>