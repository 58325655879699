export default {
  sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  },
  recursiveDig(item, prop, list = []) {
    if (!item) return list;

    if (!Array.isArray(item)) item = [item];
    item.forEach((innerItem) => {
      if (Array.isArray(innerItem[prop])) {
        list.push(...innerItem[prop]);
        innerItem[prop].forEach((ip) => {
          this.recursiveDig(ip, prop, list);
        });
      } else {
        list.push(innerItem);
        if (innerItem[prop]) this.recursiveDig(innerItem[prop], prop, list);
      }
    });

    return list;
  },
  recursiveReverse(list, prop) {
    while (list.length > 1) {
      list[list.length - 2][prop] = [list[list.length - 1]];
      list.pop();
    }
    return list;
  },
  getPageHeight(element) {
    console.log("GETTING PAGE HEIGHT");
    return (
      window.innerHeight -
      this.recursiveDig(element, "parentElement")
        .map((e) => {
          let offset = e.offsetTop;
          if (
            e.nextElementSibling &&
            getComputedStyle(e.nextElementSibling).position == "fixed"
          )
            offset += e.nextElementSibling.offsetHeight;
          console.log("parent", e);
          return offset;
        })
        .reduce((a, b) => a + b, 0)
    );
  },
  sortByOrder(a, b) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  },
  sortByRanking(a, b) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  },
  sortByQuantity(a, b) {
    if (a.quantity < b.quantity) return -1;
    if (a.quantity > b.quantity) return 1;
    return 0;
  },
  sortBySearch(list, search) {
    console.log("Sorted List", JSON.parse(JSON.stringify(list)));
    if (search && search.length > 0) {
      /*  var matchedKeys = [],
        notMatchedKeys = [];

      for (var i = 0; i < list.length; i++) {
        if (list[i].name.toLowerCase().match(search)) {
          matchedKeys.push(list[i]); //push on the basis of order
        } else {
          notMatchedKeys.push(list[i]);
        }
      }
      return matchedKeys.concat(notMatchedKeys);
 */
      return list.sort((a, b) => {
        console.log([...a.name.toLowerCase()]);
        let aCount = [...a.name.toLowerCase()].filter((l) =>
          search.includes(l)
        ).length;
        let bCount = [...b.name.toLowerCase()].filter((l) =>
          search.includes(l)
        ).length;
        if (aCount < bCount) return -1;
        if (aCount > bCount) return 1;
      });
      console.log(
        "Sorted List",
        list.sort((a, b) => {
          let aCount = [...a.name].filter((l) => search.includes(l)).length;
          let bCount = [...b.name].filter((l) => search.includes(l)).length;
          if (aCount < bCount) return -1;
          if (aCount > bCount) return 1;
          return 0;
        })
      );
    }
    console.log("Sorted ", list);
    return list;
    let string1 = prompt(a);
    let string2 = prompt(b);
    let compareNum = 0; // why are you using the Number contructor? Unneeded.

    let l = Math.min(string1.length, match.length);
    for (var i = 0; i < l; i++) {
      if (string1.charAt(i) == string2.charAt(i)) compareNum++;
    }
  },
};
