import Vue from 'vue'
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)
import helpers from './tools/helpers';
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import Scrollbar from "smooth-scrollbar";
Scrollbar.use(OverscrollPlugin);

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

Vue.directive("fontSize", {
    bind: function (el, binding, vnode) {
        el.style.fontSize = `${binding.value}px`
    },
    update: function (el, binding, vnode) {
        el.style.fontSize = `${binding.value}px`
    },
    mounted: function (el, binding, vnode) {
        el.style.fontSize = `${binding.value}px`
    },
});

Vue.directive("lineHeight", {
    bind: function (el, binding, vnode) {
        el.style.lineHeight = binding.value
    },
    update: function (el, binding, vnode) {
        el.style.lineHeight = binding.value
    },
});
Vue.directive("letterSpacing", {
    bind: function (el, binding, vnode) {
        el.style.letterSpacing = `${binding.value}px`
    },
    update: function (el, binding, vnode) {
        el.style.letterSpacing = `${binding.value}px`
    },
});
Vue.directive("fontWeight", {
    bind: function (el, binding, vnode) {
        el.style.fontWeight = binding.value
    },
    update: function (el, binding, vnode) {
        el.style.fontWeight = binding.value
    },
});


Vue.use({
    install() {
        Vue.helpers = helpers;
        Vue.prototype.$helpers = helpers;

        Vue.Scrollbar = Scrollbar;
        Vue.prototype.$Scrollbar = Scrollbar;
    },
});
export default new Vuetify({
    theme: {
        default: "light",
        customProperties: true,
        themes: {
            light: {
                primary: "#eb2e1a",
            },
            dark: {
                primary: "#101010",
            },
        },
    },
    breakpoint: {
        scrollBarWidth: 8,
    },
    build: {
        extractCss: true,
    },
});