
<style >
.v-overlay__content {
    width: 100%;
    height: 100%;
    display: flex;
}
</style>
<template>
    <v-layout :class="`d-flex fill-height flex-column justify-start grey lighten-4`">
        <v-card dark tile flat :width="this.$vuetify.breakpoint.mdAndDown ? '100%' : '90%'"
            class="mx-auto d-flex flex-column" color="transparent">
            <v-img cover :height="'100%'" width="100%" style="position:fixed;left:0;top:0"
                gradient="45deg, black, #000000ad" src="/images/national_ranking_cover.png">
            </v-img>
            <div class="d-flex flex-column">
                <v-card-title style="background: #00000075;z-index: 1">
                    <h1 class="text-uppercase pa-2" v-lineHeight="'125%'" v-fontSize="$vuetify.breakpoint.xs ? 18 : 24"
                        v-text="'NATIONAL RANKINGS BY MILITARY STRENGTH'" />
                </v-card-title>
                <v-card-text class="my-auto" style="z-index: 1">
                    <v-hover v-slot="{ hover }">
                        <p class="typeDesc" v-lineHeight="$vuetify.breakpoint.sm ? '200%' : '150%'"
                            v-fontSize="$vuetify.breakpoint.xs ? '16' : '20'"
                            v-snip="{ lines: $vuetify.breakpoint.xs && !hover ? 4 : !hover ? 4 : 0 }">
                            A formula-based ranking of the world’s foremost military powers taking into account over 50
                            different factors to determine each country’s position. Quantity and quality of armaments,
                            while important, are supplemented by equally important evaluations of training, combat
                            readiness, overseas military bases, defensive infrastructure and fortifications among many
                            other assets considered.



                            Strategic and tactical nuclear capabilities, due to the extreme caution with which they must
                            be deployed and states’ general refrain from using them, are considered a limited asset
                            rather than the decisive game changer they would be if employed freely. Nuclear powers are
                            marked accordingly in the right hand column. Due to their highly secretive nature,
                            biological warfare capabilities are not taken into consideration.



                            As well as ranking states numerically, military powers are also divided into six tiers based
                            on the league in which their military capabilities lie. Those countries in the same tier can
                            be considered near peer competitors, although the kinds of strengths and capabilities they
                            field can be entirely different.



                            Outstanding strengths of each country, if any, are listed below their names, as are links to
                            further information for each country’s aerial, naval and ground warfare capabilities. </p>
                    </v-hover>
                </v-card-text>
            </div>
        </v-card>
        <v-progress-circular v-if="loading" indeterminate color="primary" class="mx-auto mt-8" size="150">
        </v-progress-circular>
        <v-card v-else color="transparent" :elevation="4" :class="`mx-auto`"
            :width="$vuetify.breakpoint.mdAndDown ? '100%' : '650px'">
            <v-card outlined v-for="tier in data" :key="tier.id">
                <v-card dark class="mb-4">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-uppercase font-weight-bold text-center"
                                v-text="`${tier.name}  MILITARY POWERS`" />
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <component :is="$browserDetect.isIOS ? 'div' : 'v-lazy'" :transition="`scroll-y-reverse-transition`"
                    v-for="country in tier.countries" :key="country.id" min-height="140px" class="px-1">
                    <v-card class="mx-auto mb-4" shaped outlined>
                        <v-list-item>
                            <v-list-item-avatar :size="$vuetify.breakpoint.mdAndUp ? 60 : 32" class="my-auto">
                                <v-card color="grey lighten-4" outlined class="d-flex" width="100%" height="100%">
                                    <h2 v-fontSize="$vuetify.breakpoint.mdAndUp ? 26 : 22"
                                        class="ma-auto font-weight-bold" v-text="country.order" />
                                </v-card>
                            </v-list-item-avatar>
                            <v-img :src="country.country.flag" :max-width="$vuetify.breakpoint.mdAndUp ? 100 : 64"
                                :width="$vuetify.breakpoint.mdAndUp ? 100 : 64" height="64" contain />
                            <v-list-item-content>
                                <v-list-item-title class="d-flex align-center">
                                    <span v-text="country.country.name"
                                        v-fontSize="$vuetify.breakpoint.mdAndUp ? 20 : 16" class="ml-2" />
                                </v-list-item-title>

                            </v-list-item-content>

                            <v-list-item-avatar tile size="80">
                                <v-img max-height="48" max-width="48" v-if="country.nuclear" contain
                                    src="images/nuclearLogo.png" />
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-card-actions class="justify-center">
                            <v-btn outlined small text v-for="forceType in forceTypes"
                                :to="{name:'AssetsIndex' , params:{force_type:forceType.slug , ca : country.country.slug}}"
                                :key="forceType.id" v-text="forceType.name" />
                        </v-card-actions>
                        <!--    <v-card-actions class="justify-center">
                            <v-btn outlined small text v-for="forceType in forceTypes"
                                :to="{name:'AssetsIndex' , params:{force_type:forceType.slug , ca : country.country.slug}}"
                                :key="forceType.id" v-text="forceType.name" />
                        </v-card-actions> -->
                    </v-card>
                </component>
            </v-card>
        </v-card>

        <v-banner id="categorySelector" v-if="!loading" sticky app>
            <v-card :width="this.$vuetify.breakpoint.smAndDown ? '90%' : '500px'" style="z-index:2" color="#161616" tile
                dark rounded elevation="4" class="d-flex flex-column justify-center align-center py-4 mx-auto">
                <v-select hide-details outlined filled label="CHOOSE YEAR" class="mx-2" v-model="year"
                    :style="`width:${$vuetify.breakpoint.smAndDown ? '90%' : '500px'}`" :items="years"
                    style="z-index:2;" :full-width="$vuetify.breakpoint.smAndDown"
                    :menu-props="{ offsetY: true, contentClass: 'bbl', bottom: true }">
                </v-select>
            </v-card>
        </v-banner>


    </v-layout>
</template>

<script>
import Country from '../../js/database/models/country';
import ForceType from '../../js/database/models/force_type';
import MilitaryPowerTier from '../../js/database/models/military_power_tier';
import CountryRanking from '../database/models/country_ranking';
import { VLazy } from "vuetify/lib";
export default {
    data: () => ({
        data: [],
        years: [],
        year: null,
        loading: false,
    }),
    components: { VLazy },
    methods: {
        async loadData() {
            this.loading = true;
            this.data = [];
            this.data = await MilitaryPowerTier.query().with('countries', (query) => {
                query.with('country');
                query.where('year', this.year).orderBy('order', 'asc');
            }).orderBy('order').all();
            window.scrollTo(0, 0);
            this.loading = false;
        },

    },
    mounted() {
        this.loading = true;
        MilitaryPowerTier.api().get().then((res) => {
            this.years = Array.from(new Set(res.entities.countriesInfo.flatMap(c => c.year)));
            this.year = Math.max.apply(Math, this.years);
        });
    },
    created() {

    },
    computed: {
        forceTypes() {
            return ForceType.query().orderBy("order").all();
        },
    },
    watch: {
        year() {
            this.$nextTick(() => {
                this.loadData();
            });
        }
    }
}
</script>

<style>

</style>