import { Model } from "@vuex-orm/core";
import CountryReport from "./country_report";

export default class ForceTypeReport extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "forceTypeReports";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      slug: this.attr(null),
      description: this.attr(""),
      icon: this.attr(null),
      type: this.attr(null),
      order: this.attr(null),
      countries: this.hasMany(CountryReport, "report"),
      is_report: this.attr(null),
      is_ranking: this.attr(null)
    };
  }

  // *******************************************************************  //
  // *******************************************************************  //

  static apiConfig = {
    dataKey: "data",
    actions: {
      get: {
        method: "get",
        url: "/api/forceTypeReport"
      },
      dataTransformer: response => {
        console.log("DataTransformersssss", response);
        return response.data;
      }
    }
  };

  // *******************************************************************  //
  // *******************************************************************  //
}
