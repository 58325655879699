import { Model } from "@vuex-orm/core";
import Country from "./country";
import ForceCategory from "./force_category";
export default class CountryInfo extends Model {
  // This is the name used as mfca_countries_forcesodule name of the Vuex Store.
  static entity = "countriesInfo";

  static fields() {
    return {
      id: this.attr(null),
      country_id: this.attr(null),
      tier_id: this.attr(null),
      order: this.attr(null),
      flag: this.attr(null),
      nuclear: this.attr(null),
      strengths: this.attr(null),
      year: this.attr(null),
      country: this.belongsTo(Country, 'country_id'),
    };
  }
  static apiConfig = {
    actions: {
      get: {
        method: "get",
        url: "api/countryRanking"
        ,
        dataTransformer: response => {
          return response.data.data;
        }
      },
    },

  };
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
}
