import { Model } from "@vuex-orm/core";
import CountryInfo from "./country_info";

export default class MilitaryPowerTier extends Model {
  static entity = "militaryPowerTiers";
  get name_desc() {
    return this.name;
  }
  static fields() {
    return {
      id: this.number(null),
      name: this.string(""),
      order: this.number(),
      countries: this.hasMany(CountryInfo, "tier_id")
    };
  }
  static apiConfig = {
    dataKey: "data",
    actions: {
      get: {
        method: "get",
        url: "api/militaryPowerTier"
        ,
        dataTransformer: response => {
          return response.data.data;
        }
      },

    }
  };
}
