<template>
  <div>
    <slot name="title" :item="item" />
    <slot name="body" :item="item" />
    <recursive-item v-for="innerItem in items" :key="innerItem.id" :item="innerItem" :prop="prop"
      :class="`categoryItem inner`" inner :filter="filter">
      <template v-slot:title="{ item }">
        <slot name="title" :item="item" :inner="true" />
      </template>
      <template v-slot:body="{ item }">
        <slot name="body" :item="item" :inner="true" />
      </template>
    </recursive-item>
  </div>
</template>

<script>
export default {
  name: "RecursiveItem",
  props: {
    item: { type: Object, default: null, require: true },
    prop: { type: String, default: null, require: true },
    inner: { type: Boolean, default: false },
    filter: { type: Array, default: () => [] },
  },
  computed: {
    items() {
      return !this.filter.length > 0
        ? this.item[this.prop]
        : this.item[this.prop].filter((i) => this.filter.includes(i.id));
    },
  },
};
</script>

<style>
</style>
