import { Container } from "@vuex-orm/core";

export default async ctx => {
    console.log("%c **********ACTION::INIT", "background: #222; color: #fcba03");
    let errors = [];
    let uiOptions = localStorage.getItem("uiOptions");
    if (uiOptions)
        ctx.commit("saveObj", {
            key: "uiOptions",
            val: JSON.parse(uiOptions)
        });
    ctx.commit("saveObj", {
        key: "isInited",
        val: 0
    });

    for (const entity of ["forceTypes", "forceTypeReports", "countries"]) {
        if (!initData[entity] || !Array.isArray(initData[entity] || initData[entity].length == 0))
            try {
                await Container.store
                    .$db()
                    .model(entity)
                    .api()
                    .get();
                console.log("Done");
            } catch (error) {
                console.log("error");
                console.log(error);
                ctx.commit("saveObj", {
                    key: "isInited",
                    val: 2
                });
                errors.push(error);
                return;
            }
        else
            await Container.store
                .$db()
                .model(entity).insertOrUpdate({ data: initData[entity] })
    }

    if (data)
        Object.keys(data).forEach(async key =>
            Container.store
                .$db()
                .model(key).insertOrUpdate({ data: data[key] }));

    if (errors.length > 0) console.error("Errors", errors);


    else
        ctx.commit("saveObj", {
            key: "isReady",
            val: 1
        });


};
