import Vue from 'vue';
import VueRouter from 'vue-router';
import ForceType from '../database/models/force_type';
import Country from '../database/models/country';
import store from '../store';
import routes from './routes';
import ForceTypeReport from '../database/models/force_type_report';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    base: process.env.BASE_URL
});

router.beforeEach(async (to, from, next) => {
    console.log("BeforeEach Router");
    let Init = store.getters.getObj('isReady');
    let meta = {};
    if (!Init) {
        Init = await store.dispatch('INIT');
    }
    if (to.name != 'AssetsIndex') {
        meta = to.meta;
        if (to.name == 'ArmouriesIndex') {
            if (to.params.ft) {
                let forceType = ForceType.query().where('slug', to.params.ft).first();
                let title = `${forceType.title.replace(/^([a-z])|\s+([a-z])/g, i => i.toUpperCase())} - Armouries Index - Force Index | Military Watch Magazine`;
                if (to.params.ftr) {
                    let forceTypeReport = ForceTypeReport.query().where('slug', to.params.ftr).first();
                    title = ` ${forceTypeReport.name.replace(/^([a-z])|\s+([a-z])/g, i => i.toUpperCase())} - Armouries Index - Force Index - Military Watch Magazine`;
                }
                meta.title = title;
            }
        }
    } else {
        let forceType = ForceType.query().where('slug', to.params.force_type).first();
        console.log(forceType);

        if (!forceType)
            next('/');
        else {
            /* Aerial Warfare Assets - Force Index - Military Watch Magazine */
            let title = `${forceType.title.replace(/^([a-z])|\s+([a-z])/g, i => i.toUpperCase())} - Force Index - Military Watch Magazine`;
            let prependTitle = '';
            if (to.params.ca) {
                let countryA = Country.query().where('slug', to.params.ca).first();
                if (!countryA)
                    next(`/${to.params.force_type}`);
                prependTitle = countryA.name;
                if (to.params.cb) {
                    let countryB = Country.query().where('slug', to.params.cb).first();
                    if (!countryB)
                        next(`/${to.params.force_type}/${to.params.ca}`);
                    prependTitle += ` vs ${countryB.name}`;
                }
                prependTitle += ' - '
            }

            meta = {
                title: `${prependTitle}${title}`,
                description: forceType.description,
                meta: [{
                    property: 'og:title',
                    content: `${prependTitle}${title}`,
                }, {
                    property: 'og:image:image',
                    content: window.location.href + '/cover.png',
                }, {
                    property: 'og:image:secure_url',
                    content: window.location.href + '/cover.png',
                }, {
                    property: 'og:image:type',
                    content: 'image/png',
                }]
            }
        }/* og:image:secure_urlog:image */
    }

    store.commit('saveObj', { key: 'metaInfo', val: meta });
    document.title = meta.title;
    window.scrollTo(0, 0);
    next();
});

router.afterEach((to, from) => {
    /*   _gaq.push(["_set", "title", "Your Brand New Page Title"]);
      _gaq.push(["_trackPageview"]);  */
    /*   ga('set', 'page', to.path); */
    ga('send', { hitType: 'pageview', page: to.path, title: store.getters.getObj('metaInfo').title });
});

export default router;