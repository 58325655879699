import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
/* 
import helpers from '../helpers'; */

Vue.use(Vuex);

/* 
function AddCategoriesID(a) {
    a['categories_ids'] = a.categories.flatMap(x => x.id);
    return a;
} */

import database from "../database";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import axios from "axios";
VuexORM.use(VuexORMAxios, {
    axios
});

export default new Vuex.Store({
    plugins: [VuexORM.install(database)],
    state: {
        isReady: 0,
        metaInfo: {
        },
        forceTypes: [],
        inDrawer: false,
        appBarMid: false
    },
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
});
/*  */
