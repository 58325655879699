<style scoped>
footer a {
  color: white !important;
  font-weight: 500;
  font-size: 0.75rem !important;
  text-decoration: none;
  font-size: 0.75rem !important;
  padding-bottom: 4px;
}
</style>
<template>
  <v-footer dark color="black" class="text-left" elevation="4" dense bottom>
    <v-row class="mx-auto" no-gutters>
      <v-col cols="12" sm="6" md="3" class="ma-auto text-center">
        <v-card to="/" elevation="0" color="transparent">
          <v-img contain height="50px" max-width="75px" class="ma-auto" style="object-fit:contain"
            src="/images/logo.svg"></v-img>
          <h5>Military Watch Magazine</h5>
        </v-card>
        <v-btn color="primary" class="mx-1" ma-auto>
          <a href="https://militarywatchmagazine.com" style="color: white; text-transform: unset"
            class="text--white">Military Watch Magazine</a>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="pa-1 px-2">
        <h3 style="text-transform: uppercase">Fields</h3>
        <v-divider />
        <router-link class="d-block text-uppercase" :to="{
            name: 'AssetsIndex', params: { force_type: forceType.slug, ca: $route.params.ca ? $route.params.ca : null, cb: $route.params.cb ? $route.params.cb : null }
        }" v-for="forceType in forceTypes" :key="forceType.id" v-text="forceType.name"></router-link>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="pa-1 px-2">
        <h3 style="text-transform: uppercase">LINKS</h3>
        <v-divider />
        <router-link class="d-block" to="/national_ranking">NATIONAL RANKINGS</router-link>
        <router-link class="d-block text-uppercase" to="/armouries_index">Armouries Index</router-link>

      </v-col>


      <v-col cols="12" class="text-center font-weight-bold">
        <div class="footerCopyright">
          © 2018 Copyright www.militarywatchmagazine.com
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>
<style>
.compact a {
  padding: 0px 8px !important;
  border-right: rgba(0, 0, 0, 0.14) solid 1px;
  border-radius: 0;
}

/* 
.noLinkLink {
  display: flex;
} */
</style>
<script>
import { mapState } from "vuex";
import ForceType from "../database/models/force_type";

export default {
  name: "AppFooter",
  computed: {
    forceTypes() {
      return ForceType.query().orderBy("order").all();
    },
  },
};
</script>
