<template>
    <v-navigation-drawer class="ibDrawer" color="#000000de" dark :width="navDrawerWidth" v-model="drawer" :value="true"
        left app temporary>
        <v-card class="text-center mt-5" color="transparent" elevation="0" @click="$vuetify.goTo(0)">
            <v-img contain height="50px" max-width="75px" class="ma-auto" style="object-fit:contain"
                src="/images/logo.svg"></v-img>
            <span>Force Index</span>
            <v-divider></v-divider>
        </v-card>

        <v-list dense width="100%" class="bbl">
            <v-list-item href="https://militarywatchmagazine.com" color="primary" link>
                <v-list-item-content>
                    <v-list-item-title>Military Watch Magazine</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/" link>
                <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="forceType in forceTypes" :key="forceType.id" link :to="{
                name: 'AssetsIndex', params: { force_type: forceType.slug, ca: $route.params.ca ? $route.params.ca : null, cb: $route.params.cb ? $route.params.cb : null }
            }">
                <v-list-item-content>
                    <v-list-item-title>{{ forceType.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/national_ranking" link>
                <v-list-item-content>
                    <v-list-item-title>NATIONAL RANKINGS</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/armouries_index" link>
                <v-list-item-content>
                    <v-list-item-title>Armouries Index</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
 

        </v-list>
    </v-navigation-drawer>
</template>
<style>
.ibDrawer {
    font-weight: 500;
    z-index: 10 !important;
    font-size: 0.75rem !important;
    text-transform: uppercase !important;
    text-decoration: none;
    letter-spacing: 0.0892857143em;
    text-decoration: none;
    text-indent: 0.0892857143em;
}

.ibDrawer button {
    font-size: 0.75rem !important;
    text-transform: uppercase !important;
}
</style>
<script>
import { mapMutations, mapState } from 'vuex';
import ForceType from '../database/models/force_type';

export default {
    name: 'AppDrawer',
    methods: {
        ...mapMutations(['setDrawer', 'saveObj', 'setDark'])
    },
    computed: {
        ...mapState(['inDrawer', 'videos', 'sections']),
        drawer: {
            get() {
                return this.inDrawer;
            },
            set(value) {
                this.saveObj({ key: 'inDrawer', val: value });
            }
        },
        forceTypes() {
            return ForceType.query().orderBy("order").all();
        },
        navDrawerWidth() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '80%';
            }
            return '45%';
        }
    }
};
</script>
