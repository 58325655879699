import { Model } from "@vuex-orm/core";
import ForceModel from "./force_model";
import ForceType from "./force_type";
import _ from "lodash";
import helpers from "../../tools/helpers";

export default class ForceCategory extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "forceCategories";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      description: this.attr(null),
      slug: this.attr(""),
      type: this.attr(null),
      forceType: this.hasOne(ForceType, "id", "type"),
      parent: this.attr(null),
      parentCategory: this.hasOne(ForceCategory, "id", "parent"),
      order: this.attr(null),
      categories: this.hasMany(ForceCategory, "parent"),
      forceModels: this.hasMany(ForceModel, "category")
    };
  }
  static recursiveLoadParentCategory(query) {
    query.with("parentCategory", this);
  }
  static adoptCaegories(categories) {
    let cat = ForceCategory.query()
      .whereIdIn(categories)
      .orderBy("order")
      .with("parentCategory", query => {
        query.with("parentCategory", this.recursiveLoadParentCategory);
      })
      .all();
    let parents = _.uniq(helpers.recursiveDig(cat, "parentCategory"));
    parents.forEach(p => {
      p["categories"] = parents.filter(c => c.parent == p.id).sort(helpers.sortByOrder);
    });
    return _.uniq(parents)
      .filter(c => !c.parent)
      .sort(helpers.sortByOrder);

  }
  static apiConfig = {
    dataKey: "data",
    actions: {
      get: {
        method: "get",
        url: "api/forceCategory"
      }
    }
  };
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
}
