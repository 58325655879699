<style>
a {
    text-decoration: unset !important;
}

header.appNav {
    z-index: 10 !important;
}

header.appNav.black .v-toolbar__content {
    padding: 0% !important;
}

.appNav.black .v-toolbar__extension a {
    color: white !important;
}

.appNav.black .v-toolbar__extension {
    background: rgb(22, 22, 22);
    padding: 0;
}

.appNav .v-toolbar__image {
    right: 0;
    pointer-events: none;
}

.appNav .v-toolbar {
    transition: 0s all;
}

.v-toolbar__title {
    padding: 0;
    text-transform: uppercase;
    margin: auto 0;
}

.titleCard {
    border: 0px solid black;
    padding: 4px;
}

.mobiles {
    line-height: 100%;
}


.v-app-bar.v-app-bar--is-scrolled .titleCard .v-image,
.v-app-bar.v-toolbar--dense .titleCard .v-image {
    height: 50px;
    max-width: 50px;
}
</style>
<template>
    <v-app-bar :value="isHome" app  fade-img-on-scroll :prominent="isHome" :height="appBarHeight"
        ref="appNav" class="appNav black" extension-height="48px">
        <v-overlay absolute dark v-if="!isReady" />
        <v-img v-if="isHome" src="images/fca_banner.jpeg"  height="100%" cover width="100%">
            <v-overlay absolute :opacity="0">
                <v-card class="ma-auto pa-2 d-flex align-center" dark :elevation="3">
                    <v-img contain height="50px" max-width="75px" class="ma-auto" style="object-fit:contain"
                        src="/images/logo.svg"></v-img>
                    <h1 v-fontSize="$vuetify.breakpoint.mdAndUp ? 50 : 16" class="mr-4">Force Index</h1>
                </v-card>
            </v-overlay>
        </v-img>
        <template slot="extension">
            <v-layout id="midAppBar" align-center>
                <v-app-bar-nav-icon dark class="ml-0" v-if="$vuetify.breakpoint.smAndDown"
                    @click="saveObj({ key: 'inDrawer', val: true })"></v-app-bar-nav-icon>
                <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
                <v-btn color="primary" class="mx-1" ma-auto>
                    <a href="https://militarywatchmagazine.com"
                    style="color: white; text-transform: unset"
                        class="text--white">Military Watch Magazine</a>
                </v-btn>
                <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
                <v-layout v-if="!$vuetify.breakpoint.smAndDown" align-center justify-end>
                    <v-btn small text to="/" ma-auto>Home</v-btn>
                    <v-btn small text :to="{
                        name: 'AssetsIndex', params: { force_type: forceType.slug, ca: $route.params.ca ? $route.params.ca : null, cb: $route.params.cb ? $route.params.cb : null }
                    }" v-for="forceType in forceTypes" :key="forceType.id" v-text="forceType.name">
                    </v-btn>
                    <v-btn small text to="/national_ranking" ma-auto>NATIONAL RANKINGS</v-btn>
                    <v-btn small text to="/armouries_index" ma-auto>Armouries Index</v-btn>
                </v-layout>
            </v-layout>
        </template>
    </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ForceType from '../../js/database/models/force_type';
export default {
    computed: {
        ...mapState(["isReady", "appTitle"]),
        appBarHeight() {
          return this.$vuetify.breakpoint.smAndDown ? "160px" : "300px"; 
        },  appBarTitleCls() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "mobiles";
                case "sm":
                    return "title";
                case "md":
                    return "display-1";
                case "lg":
                    return "display-2";
                case "xl":
                    return "display-4";
            }
            return "display-1";
        }, forceTypes() {
            return ForceType.query().orderBy("order").all();
        },
        isHome() {
            return this.$route.path == "/";
        }, appBarTitleCls() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "mobiles";
                case "sm":
                    return "title";
                case "md":
                    return "display-1";
                case "lg":
                    return "display-2";
                case "xl":
                    return "display-4";
            }
            return "display-1";
        },
    },
    methods: {
        ...mapMutations(['saveObj']),
        onResize(v) {
            console.log("resize", v);
        }
    }
}
</script>

<style>

</style>