import { Model } from "@vuex-orm/core";
export default class ForceModel extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "ForceModels";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      slug: this.attr(""),
      cover: this.attr(null),
      category: this.attr(null),
      order: this.attr(0)
    };
  }
  static apiConfig = {
    dataKey: "records",
    actions: {
      get: {
        method: "get",
        url: "/fca_force_models"
      }
    }
  };
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
}
