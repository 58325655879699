import { Model } from "@vuex-orm/core";
import CountryForce from "./country_force";
import CountryInfo from "./country_info";
import CountryReport from "./country_report";

export default class Country extends Model {
  static entity = "countries";
  get name_desc() {
    return this.name;
  }
  static fields() {
    return {
      id: this.number(null),
      name: this.string(""),
      slug: this.string(""),
      flag: this.string(),
      order: this.attr(),
      strengths: this.string(""),
      nuclear: this.attr(),
      tier_id: this.attr(),
      models: this.hasMany(CountryForce, "country"),
      reports: this.hasMany(CountryReport, "country"),
      info: this.hasOne(CountryInfo, "country_id"),
    };
  }
  static apiConfig = {
    dataKey: "data",
    actions: {
      get: {
        method: "get",
        url:
          "/api/country",
        dataTransformer: response => {
          return response.data.data;
        }
      },
      fetchById([countries], type) {
        return this.get({
          url: `/api/country/${countries.join(",")}/${type}`,
          dataTransformer: (response) => {
            console.log("response", response.data.data);
            return response.data.data
          }
        })
      }
    }
  };
}
