import { Model } from "@vuex-orm/core";
export default class CountryReport extends Model {
  // This is the name used as mfca_countries_forcesodule name of the Vuex Store.
  static entity = "countriesReports";
  static fetchByReport(report) {
    return this.api().get(`api/countryReport?report=${report}`)
  }
  static fields() {
    return {
      id: this.attr(null),
      report: this.attr(null),
      type: this.attr(null),
      country: this.attr(null),
      quantity: this.attr(null)
    };
  }
  static apiConfig = {
    dataKey: "data",
    actions: {
      get: {
        method: "get",
        url: "api/countryReport"
        ,
        dataTransformer: response => {
          console.log("DataTransformerDataTransformer", response);
          return response.data;
        }
      },
      fetchByReport(report) {
        return this.get({
          url: `api/countryReport?report=${report}`,
          dataTransformer: (response) => {
            console.log("response", response.data.data);
            return response.data.data
          }
        })
      }
    }

  };

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
}
