import { Model } from "@vuex-orm/core";
import ForceCategory from "./force_category";
import ForceTypeReport from "./force_type_report";
export default class ForceType extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "forceTypes";
  get name_desc() {
    return this.name;
  }
  static fields() {
    return {
      id: this.attr(null),
      slug: this.attr(null),
      cover: this.attr(null),
      name: this.attr(""),
      title: this.attr(""),
      description: this.attr(""),
      cover: this.attr(""),
      background: this.attr(""),
      order: this.attr(null),
      forceTypeReports: this.hasMany(ForceTypeReport, "type"),
      categories: this.hasMany(ForceCategory, "type")
    };
  }

  // *******************************************************************  //
  // *******************************************************************  //


  static apiConfig = {
    dataKey: "data",
    actions: {
      get: {
        method: "get",
        url: "/api/forceType",
        dataTransformer: response => {
          return response.data.data;
        }
      },
    }
  };

  // *******************************************************************  //
  // *******************************************************************  //
}
